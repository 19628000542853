'use client';

import Link from "next/link";
import { cn } from "@/helpers/className.ts";
import BannerClickTracking from "@/components/tracking/BannerClickTracking";

const BannerLink = ({
                      url,
                      title,
                      label,
                      position,
                      count,
                      imageUrl,
                      componentId,
                      linkType,
                      target
                    }) => {
  const handleBannerClick = () => {
    BannerClickTracking({
      bannerTitle: title,
      bannerSubtitle: label,
      componentName: "InDeKijkerItemParagraph",
      componentID: componentId,
      componentPosition: position,
      componentCount: count,
      imageSource: imageUrl,
      targetUrl: url,
      targetPageId: url,
      targetPageName: url,
      linkTitle: title,
      linkType: linkType
    });
  };

  return (
    <Link
      href={url}
      target={target}
      className={cn(
        "stretched-link mt-auto pt-4 mr-auto flex flex-col",
      )}
      onClick={handleBannerClick}
    >
      {label && (
        <span className="text-white text-sm font-medium">{label}</span>
      )}
      <span className="text-white link no-underline !leading-tight pb-1 font-bold">{title}</span>
    </Link>
  );
};

export default BannerLink;
