"use client";

import { useEffect } from "react";
import {
  FragmentParagraphHeroVrtLogoFragment
} from "@/graphql/sdk/__generated__";
import {cn} from "@/helpers/className.ts";
import {InDeKijkerItemParagraph} from "@/components/paragraphs/child-paragraphs/InDeKijkerItemParagraph.tsx";
import {LogoAnimated} from "@/components/atoms/LogoAnimated.tsx";
import BannerVisibilityTracker from "@/components/tracking/BannerVisibilityTracker.tsx";
import React from "react";
import logoSrc from '@/assets/images/VRT-logo-animated.gif';

interface HeroCardsProps {
  data: FragmentParagraphHeroVrtLogoFragment;
  count: number;
  title?: string;
}

export const HeroCards = async ({ data, count, title }: HeroCardsProps) => {

  useEffect(() => {
    const vrtCardsClass = 'vrt-cards-page';
    document.body.classList.add(vrtCardsClass);

    // Cleanup function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove(vrtCardsClass);
    };
  }, []);
  return (
    <>
      {title && (
        <h1 className="sr-only">{title}</h1>
      )}
      <div
        id={`hero-${data.id}`}
        className={cn(
          "default-hero bg-pink-100 cards-hero relative z-0 mt-0 flex flex-col hero-full lg:px-4 max-lg:py-6 justify-center",
        )}
      >
        <BannerVisibilityTracker
          componentID={data.id}
          bannerTitle={""}
          componentName={data.__typename}
          componentPosition={1}
          componentCount={count}
          imageSource={logoSrc.src}
          targetId={`hero-${data.id}`}
        />
        <div className="container mx-auto flex flex-col gap-8 lg:gap-20 max-lg:mt-6">
          <div className="w-full items-center justify-center">
            <LogoAnimated classes={"w-[80vw] max-w-[350px] mix-blend-multiply sm:max-w-[450px] md:max-w-[800px] 2xl:max-w-[1000px] mx-auto translate-x-[15%]"}/>
          </div>
          {data?.paragraphHeroVRTInDeKijker && (
            <div className={`flex flex-col lg:flex-row gap-2 sm:gap-3 xl:gap-4 2xl:gap-5 mt-6 lg:mt-8 cards-hero__list ${data?.paragraphHeroVRTInDeKijker && data.paragraphHeroVRTInDeKijker.length > 0 ? `cards-hero__list--${data.paragraphHeroVRTInDeKijker.length}` : ``}`}>
              {data.paragraphHeroVRTInDeKijker.map((item, index) => (
                <InDeKijkerItemParagraph data={item} key={index} />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HeroCards;
