"use client";

import {
    FragmentParagraphHeroDefaultFragment,
    ImageFieldsFragment,
    ImageStyleAvailable
} from "@/graphql/sdk/__generated__";
import {getVariation} from "@/helpers/image.ts";
import {cn} from "@/helpers/className.ts";
import DrupalImage from "@/components/atoms/DrupalImage.tsx";
import {InDeKijkerItemParagraph} from "@/components/paragraphs/child-paragraphs/InDeKijkerItemParagraph.tsx";
import BannerVisibilityTracker from "@/components/tracking/BannerVisibilityTracker.tsx";
import React, {useEffect} from "react";
import BannerButton from "@/components/atoms/BannerButton.tsx";
import {HeaderSubsite} from "@/components/blocks/HeaderSubsite.tsx";

interface HeroSubsiteProps {
    data: FragmentParagraphHeroDefaultFragment,
    count: number,
    title: string,
    links?: any,
    logo?: any,
    inverse?: any,
    mainLink?: any
}

export const HeroSubsite = ({data, count, title, links, logo, inverse, mainLink}: HeroSubsiteProps) => {
    const media = data.paragraphHeroDefaultMedia
        ?.imageMediaImage as ImageFieldsFragment;
    const imageStyle = ImageStyleAvailable.LandscapeRatio32;
    const image = getVariation(media.variations, imageStyle);
    const svgImage = logo?.svgMediaImage;
    const reverseImage = inverse?.svgMediaImage;

    if (!image) {
        console.error(`Can't find image with crop ${imageStyle}`);
        return <></>;
    }

    useEffect(() => {
        const subsitePageClass = 'subsite-landing-page';
        document.body.classList.add(subsitePageClass);

        // Cleanup function to remove the class when the component unmounts
        return () => {
            document.body.classList.remove(subsitePageClass);
        };
    }, []);

    return (
        <>
            {(svgImage || reverseImage || links) && (
                <HeaderSubsite
                    svgImage={svgImage ?? undefined}
                    reverseImage={reverseImage ?? undefined}
                    subSiteLinks={links ?? undefined}
                    mainLink={mainLink}
                />
            )}
            <div
                id={`hero-${data.id}`}
                className={cn(
                    "default-hero landing-hero lg:image-overlay relative z-0 mb-10 mt-0 flex flex-col lg:flex-row hero-full items-end lg:px-4 justify-center",
                    !image && "!bg-grey overflow-hidden relative",
                )}
            >
                <BannerVisibilityTracker
                    componentID={data.id}
                    bannerTitle={data.paragraphHeroDefaultTitle ?? ""}
                    componentName={data.__typename}
                    imageSource={image.url}
                    componentPosition={1}
                    componentCount={count}
                    targetId={`hero-${data.id}`}
                />
                {image &&
                  <DrupalImage
                    url={image.url}
                    alt={media.alt || data.paragraphHeroDefaultTitle}
                    width={image.width}
                    height={image.height}
                    className="lg:inset-0 lg:-z-1 lg:h-full w-full object-cover mix-blend-multiply lg:absolute animate-[fadeIn_both_.3s]"
                    sizes="100vw"
                    priority
                  />
                }
                <div
                    className={cn(
                        "container flex flex-col gap-8 relative z-1 lg:h-full",
                    )}
                >
                    <div className="flex flex-col lg:flex-row lg:text-white w-full lg:h-full">
                        <div
                            className="flex flex-col lg:w-8/12 xl:w-9/12 lg:pr-10 xl:pr-20 2xl:pr-40 justify-start mt-auto  py-10 lg:pt-16 lg:pb-20">
                            {data.paragraphHeroDefaultTitle && (
                                <h2
                                    className={cn(
                                        "h1 lg:!mb-4 break-words lg:!text-6xl animate-[fadeIn_both_.3s]",
                                        image && "lg:text-white",
                                    )}
                                >
                                    {data.paragraphHeroDefaultTitle}
                                </h2>
                            )}

                            {data.paragraphHeroDefaultText && (
                                <div
                                    className="prose lg:prose-invert lg:text-white text-lg font-semibold lg:text-xl animate-[fadeIn_both_.3s_.05s]"
                                    dangerouslySetInnerHTML={{
                                        __html: data.paragraphHeroDefaultText.processed,
                                    }}
                                ></div>
                            )}

                            {data?.paragraphHeroDefaultLink && (
                                <div
                                    className="landing-hero__ctas flex flex-col sm:flex-row gap-2 md:gap-3 xl:gap-4 2xl:gap-5 mt-6 lg:mt-8 animate-[fadeIn_both_.3s_.1s]">
                                    {data.paragraphHeroDefaultLink.map((link, index) => (
                                        <BannerButton
                                            key={index}
                                            data={data}
                                            image={image}
                                            count={count}
                                            position={1}
                                            link={link}
                                            buttonColor={link.attributes.style === 'dark' ? 'white' : link.attributes.style}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                        {data?.paragraphHeroDefaultInDeKijker && (
                            <div
                                className="landing-hero__cards flex flex-col items-end lg:w-4/12 xl:w-3/12 gap-3 lg:gap-4 lg:overflow-y-auto no-scrollbar pb-10 lg:pt-16 lg:pb-20">
                                {data.paragraphHeroDefaultInDeKijker.map((item, index) => (
                                    <InDeKijkerItemParagraph data={item} key={index} position={1} count={count}/>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeroSubsite;
