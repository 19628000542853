
import Image from 'next/image';
import logoSrc from '@/assets/images/VRT-logo-animated.gif';

interface LogoAnimatedProps {
  classes: string;
}

export const LogoAnimated = ({classes}) => {
  return (
    <Image
      className={classes}
      src={logoSrc}
      alt="vrt logo"
      loading={"eager"}
      quality={90}
      priority
    />
  );
};
