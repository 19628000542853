import { cn } from "@/helpers/className.ts";
import DrupalImage from "@/components/atoms/DrupalImage.tsx";
import { ImageFieldsFragment, ImageStyleAvailable } from "@/graphql/sdk/__generated__";
import { getVariation } from "@/helpers/image.ts";
import BannerLink from "@/components/atoms/BannerLink";

export const InDeKijkerItemParagraph = ({ data, count, position }) => {
  const media = data.paragraphInDeKijkerMedia?.imageMediaImage as ImageFieldsFragment;
  if (!media?.variations) {
    return <></>;
  }

  const imageStyle = ImageStyleAvailable.W740;
  const image = getVariation(media.variations, imageStyle);

  if (!image) {
    console.error(`Can't find image with crop ${imageStyle}`);
    return <></>;
  }
  return (
    <div className={cn(
      "idk-item flex flex-col lg:flex-1 w-full animate-[scaleIn_both_.3s_.1s]"
    )}>
      <div className={cn(
        "paragraph default-teaser relative flex flex-col items-stretch group w-full h-full"
      )}>
        <div className="absolute inset-0 w-full h-full overflow-hidden rounded-lg image-overlay">
          <DrupalImage
            url={image.url}
            alt={media.alt || data.paragraphInDeKijkerTitle}
            width={image.width}
            height={image.height}
            sizes={"100vw"}
            className="w-full h-full transition-transform duration-300 xl:group-hover:scale-105 object-cover"
          />
        </div>
        <div className="flex flex-1 z-10 relative p-4">
          <div className="w-full h-full flex flex-col">
            {data.paragraphInDeKijkerLink && data.paragraphInDeKijkerTitle && (
              <BannerLink
                url={data.paragraphInDeKijkerLink.url}
                title={data.paragraphInDeKijkerTitle}
                label={data.paragraphInDeKijkerLabel}
                position={position}
                count={count}
                imageUrl={image?.url ?? ""}
                componentId={data.id}
                linkType="image"
                target={data.paragraphInDeKijkerLink?.attributes.target}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
